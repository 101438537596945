import { FC, useCallback, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useField } from 'formik';
import ReactSelect, { OptionTypeBase, ValueType } from 'react-select';
import { styles } from './MultiSelect.styles';
import { ValueContainer, Control, Placeholder } from './components';

interface Props {
  name: string;
  label: string;
  options: { [key: string]: string };
}

export const MultiSelect: FC<Props> = ({ name, label, options: rawOptions = {} }) => {
  const [{ onChange, value: values }, { error, touched }] = useField<string[]>(name);

  const handleChange = useCallback<(values: ValueType<OptionTypeBase, true>) => void>(
    values => onChange({ target: { value: values.map(({ value }) => value), name } }),
    [onChange, name]
  );
  const errorMessage = useMemo(() => (touched && error ? error : undefined), [touched, error]);
  const options = useMemo(() => Object.entries(rawOptions).map(([value, label]) => ({ value, label })), [rawOptions]);

  return (
    <>
      <ReactSelect
        value={values.map(value => ({ value, label: rawOptions[value] }))}
        components={{ ValueContainer, Control, Placeholder }}
        placeholder={label}
        styles={styles}
        isMulti
        options={options}
        onChange={handleChange}
        className={errorMessage ? 'is-invalid' : ''}
      />
      <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
    </>
  );
};

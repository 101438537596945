import { FC } from 'react';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes';

export const Action: FC = () => (
  <Link to={ROUTES.CreateStandup()} className="btn btn-primary text-white d-flex align-items-center">
    <FiPlus />
    <span className="ms-2">New standup</span>
  </Link>
);

import { FC } from 'react';
import { useRedirectUrl } from './hooks';
import { Card, Col, Row } from 'react-bootstrap';

export const Login: FC = () => {
  const href = useRedirectUrl();

  return (
    <Row>
      <Col>
        <Card body className="text-center">
          <h3>Add to Slack</h3>
          <a title="Log in via Slack" className="btn btn-primary">
            Install
          </a>
        </Card>
      </Col>

      <Col>
        <Card body className="text-center">
          <h3>Log in via Slack</h3>
          <a href={href} title="Log in via Slack" className="btn btn-primary">
            Log in
          </a>
        </Card>
      </Col>
    </Row>
  );
};

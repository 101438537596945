import { FC, useContext } from 'react';
import { OptionTypeBase, ValueContainerProps } from 'react-select';
import { FocusContext } from '../contexts';

export const ValueContainer: FC<ValueContainerProps<OptionTypeBase, true>> = props => {
  const { children, className, cx, isMulti, getStyles, hasValue, selectProps } = props;
  const isFocused = useContext(FocusContext);

  return (
    <div
      style={getStyles('valueContainer', props)}
      className={cx(
        {
          'value-container': true,
          'value-container--is-multi': isMulti,
          'value-container--has-value': hasValue,
        },
        className
      )}
    >
      <label style={getStyles('placeholder', { ...props, isFocused })}>{selectProps.placeholder}</label>
      {children}
    </div>
  );
};

import { FC, useMemo } from 'react';
import { SlackUser } from '../../../interfaces';

interface Props {
  users: string[];
  slackUsers: SlackUser[];
}

export const Participants: FC<Props> = ({ users: userIds, slackUsers }) => {
  const users: SlackUser[] = useMemo(
    () =>
      userIds.map(userId => slackUsers.find(slackUser => slackUser.slackId === userId)).filter(Boolean) as SlackUser[],
    [userIds, slackUsers]
  );

  return <>{users.map(({ name, realName }) => `${realName} (${name})`).join(', ')}</>;
};

import { FC } from 'react';
import { ControlProps, OptionTypeBase } from 'react-select';
import { FocusContext } from '../contexts';

export const Control: FC<ControlProps<OptionTypeBase, true>> = props => {
  const { children, cx, getStyles, className, isDisabled, isFocused, innerRef, innerProps, menuIsOpen } = props;

  return (
    <div
      ref={innerRef}
      style={getStyles('control', props)}
      className={cx(
        {
          control: true,
          'control--is-disabled': isDisabled,
          'control--is-focused': isFocused,
          'control--menu-is-open': menuIsOpen,
        },
        className
      )}
      {...innerProps}
    >
      <FocusContext.Provider value={isFocused}>{children}</FocusContext.Provider>
    </div>
  );
};

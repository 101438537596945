import { createContext, ReactElement } from 'react';

export interface TitleContextInterface {
  title: string;
  setTitle: (title: string) => void;
  setBack: (back: string) => void;
  setAction: (action?: ReactElement) => void;
}

const defaultContext: TitleContextInterface = {
  title: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTitle: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setBack: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAction: () => {},
};

export const TitleContext = createContext<TitleContextInterface>(defaultContext);

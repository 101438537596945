import { ReactElement, useContext, useEffect } from 'react';
import { TitleContext } from '../contexts/title.context';

type UseTitleHook = (title?: string, options?: { back?: string; action?: ReactElement }) => void;

export const useTitle: UseTitleHook = (title = '', { back = '', action = undefined } = {}): void => {
  const { setTitle, setBack, setAction } = useContext(TitleContext);

  useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);

  useEffect(() => {
    setBack(back);
  }, [back, setBack]);

  useEffect(() => {
    setAction(action);
  }, [action, setAction]);
};

import { client } from '../client';

export const oauth = {
  async logout(): Promise<unknown> {
    return client.patch('/oauth/v2/logout').then(({ data }) => {
      console.log('DATA', data);
      return data;
    });
  },
};

import { FC, FormEvent, PropsWithChildren, useCallback } from 'react';
import { FormikHelpers, FormikProvider, FormikValues, useFormik } from 'formik';
import { AnySchema } from 'yup';
import { Form as BootstrapForm } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

export type SubmitFunc = (params: unknown) => Promise<unknown>;

interface Props {
  initialValues: FormikValues;
  validationSchema: AnySchema;
  onSubmit: (params: any) => any;
  onSuccess?: (result: unknown, actions: FormikHelpers<FormikValues>) => void;
}

export const Form: FC<PropsWithChildren<Props>> = ({
  children,
  initialValues,
  validationSchema,
  onSubmit: originalOnSubmit,
  onSuccess,
}) => {
  const mutation = useMutation(originalOnSubmit);

  const onSubmit = useCallback(
    async (values, actions) => {
      try {
        const result = await mutation.mutateAsync(values);
        if (onSuccess) {
          onSuccess(result, actions);
        }
      } catch (e) {
        const details = (e as AxiosError).response?.data?.details;

        if (details) {
          actions.setErrors(details);
        }
      }
    },
    [mutation, onSuccess]
  );

  const formik = useFormik({ initialValues, onSubmit, validationSchema, validateOnChange: false });
  const onFormSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      formik.submitForm();
    },
    [formik]
  );

  return (
    <FormikProvider value={formik}>
      <BootstrapForm onSubmit={onFormSubmit}>{children}</BootstrapForm>
    </FormikProvider>
  );
};

import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from './routes';
import { AuthLayout } from './layouts/AuthLayout';
import { Login } from './pages/Login';

export const Auth: FC = () => (
  <AuthLayout>
    <Switch>
      <Redirect from={ROUTES.Auth()} exact to={ROUTES.Login()} />
      <Route path={ROUTES.Login()} exact component={Login} />
    </Switch>
  </AuthLayout>
);

import { useMemo } from 'react';
import { SLACK_APP_CONFIG } from '../../../constants/slack-app';

export const useRedirectUrl = (): string => {
  return useMemo<string>(() => {
    const url = new URL(SLACK_APP_CONFIG.OAuthEndpoint);

    url.searchParams.set('response_type', 'code');
    url.searchParams.set('scope', SLACK_APP_CONFIG.Scopes.join(','));
    url.searchParams.set('state', 'login');
    url.searchParams.set('client_id', process.env.REACT_APP_SLACK_APP_CLIENT_ID || '');
    url.searchParams.set('redirect_uri', process.env.REACT_APP_SLACK_APP_OAUTH_REDIRECT || '');

    return url.href;
  }, []);
};

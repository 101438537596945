export const ROUTES = {
  Main: (): string => '/',
  Auth: (): string => '/auth',
  Login: (): string => '/auth/login',
  Profile: (): string => '/profile',
  Standups: (): string => '/profile/standups',
  Standup: (id = ':id'): string => `/profile/standups/${id}`,
  SendAnswers: (id = ':id'): string => `/profile/standups/${id}/send-answers`,
  CreateStandup: (): string => '/profile/standups/create',
  EditStandup: (id = ':id'): string => `/profile/standups/${id}/edit`,
  Billing: (): string => '/profile/billing',
  Settings: (): string => '/profile/settings',
  Logout: (): string => '/profile/logout',
};

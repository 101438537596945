import { FC, useMemo } from 'react';
import { SlackChannel } from '../../interfaces';

interface Props {
  channel: string;
  slackChannels: SlackChannel[];
}

export const Channel: FC<Props> = ({ channel: channelId, slackChannels }) => {
  const channel: SlackChannel | undefined = useMemo(
    () => slackChannels.find(channel => channel.id === channelId),
    [channelId, slackChannels]
  );

  if (!channel) {
    return null;
  }

  return <div>#{channel.name}</div>;
};

import { FC, useCallback, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useField } from 'formik';

interface Props {
  name: string;
  label: string;
}

export const Checkbox: FC<Props> = ({ name, label }) => {
  const [{ onChange, value }, { error, touched }] = useField(name);

  const handleChange = useCallback(() => onChange({ target: { value: !value, name } }), [onChange, name, value]);
  const errorMessage = useMemo(() => (touched && error ? error : undefined), [touched, error]);
  const checked = useMemo(() => Boolean(value), [value]);

  return (
    <>
      <div key={`input-${name}`} className="mb-3">
        <Form.Check
          type="checkbox"
          id={`input-${name}`}
          label={label}
          value={value}
          checked={checked}
          onChange={handleChange}
          isInvalid={!!errorMessage}
        />
      </div>
      <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
    </>
  );
};

import { FieldArray, useField } from 'formik';
import { ComponentType, FC, useMemo } from 'react';
import { Form, Button } from 'react-bootstrap';
import './FormArray.scss';

interface Props {
  name: string;
  component: ComponentType<{ name: string }>;
  initialValue: unknown;
  newElementText?: string;
}

export const FormArray: FC<Props> = ({
  name,
  component: Component,
  initialValue,
  newElementText = 'Add new element',
}) => {
  const [{ value: values }, { error, touched }] = useField(name);
  const errorMessage = useMemo(() => (touched && error ? error : undefined), [touched, error]);

  return (
    <FieldArray
      name={name}
      render={helpers => (
        <div className="form-array-wrapper mb-3">
          {values.map((value: unknown, index: number) => (
            <div key={index} className="d-flex justify-content-stretch mb-3">
              <Component name={`${name}.${index}`} />
              {index > 0 && (
                <Button type="button" className="ms-3" onClick={() => helpers.remove(index)}>
                  -
                </Button>
              )}
            </div>
          ))}
          <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
          <Button type="button" onClick={() => helpers.push(initialValue)}>
            {newElementText}
          </Button>
        </div>
      )}
    />
  );
};

import { Answers, CreateAnswersDto } from '../../interfaces';
import { client } from '../client';

export const answers = {
  async send(data: CreateAnswersDto): Promise<Answers> {
    return client.post('/answers', data).then(({ data }) => data);
  },
  async getPending(standupId: string): Promise<Answers> {
    return client.get(`/answers/${standupId}/pending`).then(({ data }) => data);
  },
};

import { FC, useCallback, useMemo } from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';
import { useField } from 'formik';

interface Props {
  name: string;
  label: string;
  options: { [key: string]: string };
}

export const Select: FC<Props> = ({ name, label, options = {} }) => {
  const [{ onChange, value }, { error, touched }] = useField(name);

  const handleChange = useCallback(({ target: { value } }) => onChange({ target: { value, name } }), [onChange, name]);
  const errorMessage = useMemo(() => (touched && error ? error : undefined), [touched, error]);

  return (
    <FloatingLabel controlId={`input-${name}`} label={label} className="mb-3">
      <Form.Select onChange={handleChange} value={value} isInvalid={!!errorMessage}>
        {Object.entries(options).map(([optionValue, optionLabel]) => (
          <option key={optionValue} value={optionValue}>
            {optionLabel}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
    </FloatingLabel>
  );
};

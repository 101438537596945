import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Checkbox } from '../Form';
import { WEEK_DAYS } from '../../constants/week-days';

export const Weekdays: FC = () => (
  <Row>
    {Object.entries(WEEK_DAYS).map(([weekNumber, label]) => (
      <Col key={weekNumber} xs="3" md="2" lg="1">
        <Checkbox name={`weekDays[${weekNumber}]`} label={label} />
      </Col>
    ))}
  </Row>
);

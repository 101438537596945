import { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  message?: string;
  link?: string;
}

export const EmptyState: FC<Props> = ({ message = 'No elements yet.', link }) => (
  <div className="text-secondary">
    <div>
      {message}
      {link && (
        <span>
          {' '}
          Add your first one <Link to={link}>here</Link>
        </span>
      )}
    </div>
  </div>
);

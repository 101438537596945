import { SlackChannel, SlackUser } from '../../interfaces';
import { client } from '../client';

export const slack = {
  async getChannels(): Promise<SlackChannel[]> {
    return client.get('/slack/channels').then(({ data }) => data);
  },
  async getUsers(): Promise<SlackUser[]> {
    return client.get('/slack/users').then(({ data }) => data);
  },
};

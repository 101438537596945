import { FC } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { FiUsers, FiMessageSquare, FiSlack } from 'react-icons/fi';
import './Charts.scss';

const data = [
  { weekDay: 'Su', Standups: 1 },
  { weekDay: 'Mo', Standups: 4 },
  { weekDay: 'Tu', Standups: 2 },
  { weekDay: 'We', Standups: 5 },
  { weekDay: 'Th', Standups: 4 },
  { weekDay: 'Fr', Standups: 5 },
  { weekDay: 'Sa', Standups: 0 },
];

const TICK_COLOR = '#212529';

export const Charts: FC = () => (
  <Row className="dashboard">
    <Col xs="12" md="4" className="d-flex">
      <Card body className="flex-grow-1">
        <div className="text-center">Last week</div>
        <ResponsiveContainer width="100%" height={120}>
          <BarChart data={data} margin={{ top: 0, bottom: 0, right: 0, left: 0 }}>
            <Tooltip cursor={false} />
            <XAxis
              dataKey="weekDay"
              tickCount={data.length}
              interval={0}
              tickLine={false}
              tick={{ fill: TICK_COLOR, fontWeight: 300 }}
            />
            <YAxis axisLine={false} tickLine={false} tick={{ fill: TICK_COLOR, fontWeight: 300 }} width={23} />
            <Bar dataKey="Standups" fill="#212529" />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </Col>
    <Col xs="12" md="4" className="d-flex">
      <Card className="flex-grow-1">
        <Card.Body className="d-flex flex-column">
          <div className="text-center">Stats</div>
          <Row className="flex-grow-1">
            <Col className="d-flex flex-column align-items-center justify-content-center text-center">
              <FiUsers size="40" strokeWidth="1.3" />
              <div className="mt-2 fw-light text-secondary">7 members</div>
            </Col>
            <Col className="d-flex flex-column align-items-center justify-content-center text-center">
              <FiMessageSquare size="40" strokeWidth="1.3" />
              <div className="mt-2 fw-light text-secondary">2 standups</div>
            </Col>
            <Col className="d-flex flex-column align-items-center justify-content-center text-center">
              <FiSlack size="40" strokeWidth="1.3" />
              <div className="mt-2 fw-light text-secondary">2 channels</div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
    <Col xs="12" md="4" className="d-flex">
      <Card className="flex-grow-1">
        <Card.Body className="d-flex flex-column">
          <div className="text-center">Next Standups</div>
          <div className="flex-grow-1 d-flex flex-column justify-content-center">
            <div className="mb-2 calendar-day text-uppercase">Tomorrow</div>
            <div className="calendar-event fw-light">
              <div className="d-flex justify-content-between flex-grow-1">
                <div>
                  Test <span className="text-secondary">#codecrafter</span>
                </div>
                <div>12:00</div>
              </div>
            </div>
            <div className="calendar-event fw-light">
              <div className="d-flex justify-content-between flex-grow-1">
                <div>
                  New standup <span className="text-secondary">#codecrafter</span>
                </div>
                <div>17:00</div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  </Row>
);

import { FC, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { api } from '../../api';
import { ErrorMessage } from '../../components/ErrorMessage';
import { Loading } from '../../components/Loading';
import { useTitle } from '../../hooks/useTitle.hook';
import { SlackChannel, Standup as StandupInterface } from '../../interfaces';
import { Charts, Standup } from './components';
import { Action } from './Action';
import { EmptyState } from '../../components/EmptyState';
import { ROUTES } from '../../routes';

export const Standups: FC = () => {
  const action = useMemo(() => <Action />, []);

  useTitle('Dashboard', { action });

  const { data, error, isFetching } = useQuery<StandupInterface[], Error>('standups', api.standups.getAll);
  const { data: channels, isFetching: isChannelsFetching } = useQuery<SlackChannel[], Error>(
    'slack channels',
    api.slack.getChannels
  );

  if ((isFetching && !data) || (isChannelsFetching && !channels)) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <>
      <section className="section">
        <h4>Overview</h4>
        <Charts />
      </section>
      <h4>Standups</h4>
      <Row>
        {data?.map(standup => (
          <Col xs="12" md="6" key={standup._id}>
            <Standup standup={standup} channels={channels || []} />
          </Col>
        ))}
        {!data?.length && <EmptyState link={ROUTES.CreateStandup()} />}
      </Row>
    </>
  );
};

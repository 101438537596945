import { FC, useMemo } from 'react';
import * as Yup from 'yup';
import { FieldArray } from 'formik';
import { Form, TextArea, SubmitButton } from '../../../components/Form';
import { Answers, CreateAnswersDto, Standup, StandupQuestion } from '../../../interfaces';

const validationSchema = Yup.object({
  answers: Yup.array()
    .of(Yup.object({ question: Yup.string().required(), content: Yup.string().required() }))
    .min(1),
});

interface Props {
  standup: Standup;
  currentAnswers?: Answers;
  questions: StandupQuestion[];
  onSubmit: (data: CreateAnswersDto) => Promise<Answers>;
  onSuccess?: (result: unknown) => void;
}

export const SendStatus: FC<Props> = ({ standup, currentAnswers, questions, onSubmit, onSuccess }) => {
  const initialValues = useMemo(
    () => ({
      standup: standup._id,
      answers: questions.map(question => ({
        question: question._id,
        content: currentAnswers?.answers.find(answer => answer.question === question._id)?.content || '',
      })),
    }),
    [questions, currentAnswers]
  );

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} onSuccess={onSuccess}>
      <FieldArray
        name="answers"
        render={() => (
          <div className="form-array-wrapper mb-3">
            {questions.map((question: StandupQuestion, index: number) => (
              <div key={question._id} className="d-flex justify-content-stretch mb-3">
                <TextArea name={`answers.[${index}].content`} label={question.content} />
              </div>
            ))}
          </div>
        )}
      />
      <SubmitButton>Send</SubmitButton>
    </Form>
  );
};

import { FC } from 'react';
import { useQuery } from 'react-query';
import { api } from '../../api';
import { ErrorMessage } from '../../components/ErrorMessage';
import { Loading } from '../../components/Loading';
import { useTitle } from '../../hooks/useTitle.hook';

export const Settings: FC = () => {
  useTitle('Settings');

  // const { data, error, isFetching } = useQuery<StandupInterface[], Error>('standups', api.standups.getAll);
  // const { data: channels, isFetching: isChannelsFetching } = useQuery<SlackChannel[], Error>(
  //   'slack channels',
  //   api.slack.getChannels
  // );

  // if ((isFetching && !data) || (isChannelsFetching && !channels)) {
  //   return <Loading />;
  // }

  // if (error) {
  //   return <ErrorMessage error={error} />;
  // }

  return <>Work in progress</>;
};

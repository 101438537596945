import { FC } from 'react';
import { Badge, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FiClock, FiUser, FiMessageSquare } from 'react-icons/fi';
import { Channel } from '../../../components/Channel';
import { Standup as StandupInterface, SlackChannel } from '../../../interfaces';
import { ROUTES } from '../../../routes';
import { WEEK_DAYS } from '../../../constants/week-days';
import './Standup.scss';

interface Props {
  standup: StandupInterface;
  channels: SlackChannel[];
}

export const Standup: FC<Props> = ({ standup, channels }) => (
  <Link to={ROUTES.Standup(standup._id)} className="d-block mb-3 text-decoration-none text-dark">
    <Card className="card-standup">
      <Card.Body className="d-flex flex-row align-items-center">
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <span className="fs-4 me-2">{standup.name}</span>
              <Badge pill bg={standup.enabled ? 'success' : 'danger'} className="fw-normal">
                {standup.enabled ? 'active' : 'inactive'}
              </Badge>
            </div>
            <div className="text-center d-flex align-items-center justify-content-center fs-4">
              <FiClock size={18} className="me-2" />
              {standup.time}
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <Channel channel={standup.channel} slackChannels={channels} />
            <div className="timezone">{standup.timezone}</div>
          </div>
          <div className="d-flex justify-content-between mt-1">
            <div className="d-flex">
              <div className="fw-light me-3">
                <FiUser /> {standup.users.length}
              </div>
              <div className="fw-light">
                <FiMessageSquare /> {standup.questions.length}
              </div>
            </div>
            <div className="d-flex justify-content-between fs-6">
              {Object.entries(WEEK_DAYS)
                .filter(([weekNumber]) => standup.weekDays.includes(Number(weekNumber)))
                .map(([weekNumber, label]) => (
                  <div key={weekNumber} className="ms-2 text-success">
                    {label.slice(0, 2)}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  </Link>
);

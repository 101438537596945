import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTitle } from '../../hooks/useTitle.hook';
import { useQuery } from 'react-query';
import { api } from '../../api';
import { ErrorMessage } from '../../components/ErrorMessage';
import { ROUTES } from '../../routes';

export const Logout: FC = () => {
  useTitle('Logout');

  const history = useHistory();

  const { error } = useQuery<unknown, Error>('logout', api.oauth.logout, {
    onSuccess: () => history.push(ROUTES.Main()),
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return <>Logging out...</>;
};

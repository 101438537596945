import { OptionTypeBase, StylesConfig } from 'react-select';

const primaryColor = '#212529'; //'#2a9d8f';
const backgroundColor = '#fff'; //'#264653';
const textColor = '#212529'; //'#fff';
const borderColor = '#ced4da';

export const styles: StylesConfig<OptionTypeBase, true> = {
  container: provided => ({
    ...provided,
    marginBottom: '1rem',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '6px 8px 0 0',
    position: 'initial',
  }),
  control: (provided, state) => ({
    ...provided,
    background: backgroundColor,
    borderColor: borderColor,
    padding: '1rem 0.75rem',
    height: 'calc(3.5rem + 2px)',
    lineHeight: 1.25,
    boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(0 0 0 / 25%)' : 'none',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    top: 0,
    left: 0,
    margin: 0,
    color: textColor,
    height: '100%',
    padding: '1rem 0.75rem',
    transform: 'none',
    lineHeight: '24px',
    pointerEvents: 'none',
    transformOrigin: '0 0',
    transition: 'opacity 0.1s ease-in-out, transform 0.1s ease-in-out',
    ...(state.hasValue || state.isFocused
      ? { opacity: 0.65, transform: 'scale(0.85) translateY(-0.5rem) translateX(0.15rem)' }
      : {}),
  }),
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: primaryColor,
  }),
  dropdownIndicator: provided => ({ ...provided, color: `${primaryColor} !important` }),
  multiValue: provided => ({
    ...provided,
    backgroundColor,
    border: `1px solid ${primaryColor}`,
    borderRadius: 4,
  }),
  multiValueLabel: provided => ({ ...provided, fontSize: '65%', padding: 1, color: textColor }),
  input: provided => ({ ...provided, color: textColor }),
  menu: provided => ({
    ...provided,
    backgroundColor,
    border: `1px solid ${primaryColor}`,
    boxShadow: 'none', //'0 0 0 0.25rem rgb(0 0 0 / 25%)',
  }),
  option: provided => ({
    ...provided,
    backgroundColor: 'transparent',
    ':active': { backgroundColor: textColor },
    ':hover': { backgroundColor: primaryColor, color: backgroundColor, cursor: 'pointer' },
  }),
};

import { Standup, UpdateStandupDto } from '../../interfaces';
import { CreateStandupDto } from '../../interfaces';
import { client } from '../client';

export const standups = {
  async create(data: CreateStandupDto): Promise<Standup> {
    return client.post('/standups', data).then(({ data }) => data);
  },
  async update(id: string, data: UpdateStandupDto): Promise<Standup> {
    return client.patch(`/standups/${id}`, data).then(({ data }) => data);
  },
  async getAll(): Promise<Standup[]> {
    return client.get('/standups').then(({ data }) => data);
  },
  async get(id: string): Promise<Standup> {
    return client.get(`/standups/${id}`).then(({ data }) => data);
  },
};
